<template>
  <div class="email-not-received mt-3">
    <p class="subtitle">Essa é mais uma medida de segurança para garantir que você é a única pessoa acessando sua conta Mercafacil.</p>
    <p class="email-text">
      Disparamos um e-mail com um código de acesso para <strong>{{ username }}</strong
      >, para autorizar esse acesso, basta inserir o código recebido abaixo:
    </p>

    <pin-input :error="pinError" @send-code="handlePin($event)" />

    <v-row v-if="showReSendButton" class="mt-3" no-gutters>
      <v-btn v-if="timer > 0" class="btn-submit-form" color="primary" disabled type="submit"
        >Reenviar o código em <strong class="pl-1">{{ secondsText }}</strong></v-btn
      >
      <v-btn v-else class="btn-submit-form" color="primary" :loading="loading" type="submit" width="100%" @click="$emit('resend-code')"
        >Reenviar o código</v-btn
      >
    </v-row>

    <v-checkbox v-model="dontVerifyAgain" color="#00b395" hide-details label="Não verificar novamente neste navegador" single-line></v-checkbox>
    <div class="link-container mt-6">
      <span class="link" @click="$emit('email-not-received')">Não recebi meu e-mail</span>
    </div>

    <back-to-login @go-back="goBack" />
  </div>
</template>

<script>
export default {
  name: 'EmailNotReceived',
  components: {
    PinInput: () => import('@/components/PinInput.vue'),
    BackToLogin: () => import('@/components/BackToLogin.vue'),
  },
  props: {
    username: {
      type: String,
      default: '',
    },
    pinError: {
      type: String,
      default: '',
    },
    showReSendButton: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dontVerifyDeviceAgain: {
      type: Boolean,
      default: false,
    },
    timer: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    preventSendCode: false,
    time: null,
  }),
  computed: {
    secondsText() {
      return this.timer > 1 ? this.timer + ' segundos' : this.timer + ' segundo'
    },
    dontVerifyAgain: {
      get() {
        return this.dontVerifyDeviceAgain
      },
      set(value) {
        this.$emit('update:dontVerifyDeviceAgain', value)
      },
    },
  },
  beforeDestroy() {
    this.clearTime()
  },
  methods: {
    goBack() {
      this.$emit('go-back')
    },
    handlePin(code) {
      this.$emit('remove-error')
      if (code.length < 6) return
      if (this.preventSendCode) return
      this.preventSendCode = true
      this.time = setTimeout(() => {
        this.$emit('send-code', code)
        this.preventSendCode = false
      }, 300)
    },
    clearTime() {
      clearTimeout(this.time)
      this.time = null
    },
  },
}
</script>

<style lang="scss" scoped>
.email-not-received {
  & p {
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    font-weight: 400;
  }
  & .subtitle {
    color: #4b525a;
  }

  & strong {
    color: #00b395;
  }

  & .email-text {
    color: #6b7580;
  }

  & .link-container {
    text-align: center;
  }
  & ::v-deep label {
    color: #4b525a;
    font-size: 14px;
  }
}
</style>
